var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"document",staticClass:"elevation-1 banking-profile-table",attrs:{"hide-default-footer":"","fixed-header":"","headers":_vm.headersItems,"items":_vm.rowsItems,"item-key":"id","items-per-page":_vm.rowsItems.length,"loading":_vm.loading,"custom-sort":_vm.customSort,"loading-text":_vm.$t('bankingProfile.table.loadingText')},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([_vm._l((_vm.headersItems),function(h,index){return {key:_vm.getSlotName(h),fn:function(ref){
var header = ref.header;
return [(index === 0)?_c('span',{key:index},[_c('v-menu',{attrs:{"content-class":"dots-menu","nudge-bottom":"30","bottom":"","rigth":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"justify-start menuBtn",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true),model:{value:(_vm.dotsMenu),callback:function ($$v) {_vm.dotsMenu=$$v},expression:"dotsMenu"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-list-item',{on:{"click":_vm.saveXLS}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('bankingProfile.table.saveXLS')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('bankingProfile.table.explanationText')))])],1)],1)],1)],1)],1)],1):_vm._e(),(index !== 0)?_c('span',{key:index},[_vm._v(" "+_vm._s(_vm.$t(("bankingProfile.table.headers." + (header.text))))+" ")]):_vm._e()]}}}),{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.color,"indeterminate":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Keine Daten vorhanden")])]},proxy:true},{key:"item.landkuerzel",fn:function(ref){
var item = ref.item;
return [(item.landkuerzel)?_c('div',{staticClass:"country-container"},[_c('img',{attrs:{"width":"32","height":"32","src":(_vm.url + "/api/proxy?url=//cdn1.fmh.de/images/common/flags/32/" + (encodeURIComponent(
          ((item.landkuerzel) + ".png")
        ))),"alt":"flag"}}),(item.landname)?_c('span',[_vm._v(_vm._s(item.landname))]):_vm._e()]):_vm._e()]}},{key:"item.ordinalNumber",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.ordinalNumber)+" ")]}},{key:"item.anbietername",fn:function(ref){
        var item = ref.item;
return [(item['anbieterLogo'])?_c('div',[_c('img',{attrs:{"width":"120","height":"40","src":(_vm.url + "/api/proxy?url=" + (encodeURIComponent(
          item['anbieterLogo']
        ))),"alt":item['anbietername']}})]):_vm._e(),_c('span',{staticStyle:{"font-size":"smaller"}},[_vm._v(_vm._s(item['anbietername']))])]}},{key:"item.standortlaender",fn:function(ref){
        var item = ref.item;
return [((item.standortlaender || []).length <= 3)?_c('span',_vm._l((item.standortlaender),function(str,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(str)),(index !== (item.standortlaender || []).length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('span',[_vm._l(((item.standortlaender || []).slice(0, 3)),function(str,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(str)),(index !== (item.standortlaender || []).slice(0, 3).length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),_c('span',{staticStyle:{"color":"#00ab97","white-space":"nowrap"}},[_vm._v(_vm._s(' ')+"+"+_vm._s((item.standortlaender || []).slice( 3, (item.standortlaender || []).length ).length)+" weitere")])],2)]}},{key:"item.schwerpunkte",fn:function(ref){
        var item = ref.item;
return _vm._l((item.schwerpunkte),function(str,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(str)),(index !== item.schwerpunkte.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})}},{key:"item.tochtergesellschaften",fn:function(ref){
        var item = ref.item;
return _vm._l((item.tochtergesellschaften),function(str,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(str)),(index !== item.tochtergesellschaften.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})}},{key:"item.eigenkapitalrenditeDurchschnitt",fn:function(ref){
        var item = ref.item;
return [(item.eigenkapitalrenditeDurchschnitt)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.fieldValue( item.eigenkapitalrenditeDurchschnitt, 'eigenkapitalrenditeDurchschnitt' )))])]):_vm._e()]}},{key:"item.boersennotiert",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.boersennotiert === false)?_c('span',[_vm._v("Nein")]):_c('span',[_vm._v("Ja")])])]}},{key:"item.bankratings",fn:function(ref){
        var item = ref.item;
return [(item.bankratings)?_c('div',{staticClass:"white-space"},_vm._l((item.bankratings),function(bank,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(bank.ratingagentur)+":")]),_vm._v("   "+_vm._s(bank.rating || '-')+"   ")])}),0):_vm._e()]}},{key:"item.leverageRatio",fn:function(ref){
        var item = ref.item;
return [(item.leverageRatio)?_c('div',{staticClass:"white-space"},_vm._l((item.leverageRatio),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.leverageRatio, 'leverageRatio'))+"   ")])}),0):_vm._e()]}},{key:"item.eigenkapital",fn:function(ref){
        var item = ref.item;
return [(item.eigenkapital)?_c('div',{staticClass:"white-space"},_vm._l((item.eigenkapital),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.eigenkapital, 'eigenkapital'))+"   ")])}),0):_vm._e()]}},{key:"item.hypothekenvolumen",fn:function(ref){
        var item = ref.item;
return [(item.hypothekenvolumen)?_c('div',{staticClass:"white-space"},_vm._l((item.hypothekenvolumen),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.bezeichnung)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.hypothekenvolumen, 'hypothekenvolumen'))+"   ")])}),0):_vm._e()]}},{key:"item.bilanzsumme",fn:function(ref){
        var item = ref.item;
return [(item.bilanzsumme)?_c('div',{staticClass:"white-space"},_vm._l((item.bilanzsumme),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.bilanzsumme, 'bilanzsumme'))+"   ")])}),0):_vm._e()]}},{key:"item.marktkapitalisierung",fn:function(ref){
        var item = ref.item;
return [(item.marktkapitalisierung)?_c('div',{staticClass:"white-space"},_vm._l((item.marktkapitalisierung),function(obj,index){return _c('div',{key:index},[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.marktkapitalisierung, 'marktkapitalisierung'))+"   ")])])}),0):_vm._e()]}},{key:"item.gesamtkapitalquote",fn:function(ref){
        var item = ref.item;
return [(item.gesamtkapitalquote)?_c('div',{staticClass:"white-space"},_vm._l((item.gesamtkapitalquote),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.gesamtkapitalquote, 'gesamtkapitalquote'))+"   ")])}),0):_vm._e()]}},{key:"item.kernkapitalquote",fn:function(ref){
        var item = ref.item;
return [(item.kernkapitalquote)?_c('div',{staticClass:"white-space"},_vm._l((item.kernkapitalquote),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.kernkapitalquote, 'kernkapitalquote'))+"   ")])}),0):_vm._e()]}},{key:"item.eigenkapitalrendite",fn:function(ref){
        var item = ref.item;
return [(item.eigenkapitalrendite)?_c('div',{staticClass:"white-space"},_vm._l((item.eigenkapitalrendite),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.eigenkapitalrendite, 'eigenkapitalrendite'))+"   ")])}),0):_vm._e()]}},{key:"item.gesellschafter",fn:function(ref){
        var item = ref.item;
return [(item.gesellschafter)?_c('div',{staticClass:"white-space"},_vm._l((item.gesellschafter),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.gesellschaft)+": ")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.anteil, 'gesellschafter'))+"   ")])}),0):_vm._e()]}},{key:"item.mitarbeiter",fn:function(ref){
        var item = ref.item;
return [(item.mitarbeiter)?_c('div',{staticClass:"white-space"},_vm._l((item.mitarbeiter),function(obj,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(obj.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValue(obj.anzahl, 'mitarbeiter'))+"   ")])}),0):_vm._e()]}},{key:"item.filialen",fn:function(ref){
        var item = ref.item;
return [(item.filialen)?_c('div',{staticClass:"white-space"},_vm._l((item.filialen),function(filial,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(filial.jahr)+":")]),_vm._v("   "+_vm._s(_vm.fieldValueFilialen(filial.anzahl))+"   ")])}),0):_c('div',[_vm._v("keine")])]}},{key:"item.einlagensicherung",fn:function(ref){
        var item = ref.item;
return [(item.einlagensicherung !== '')?_c('div',{staticClass:"white-space"},_vm._l((item.einlagensicherung),function(data,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',[(data.landname && data.gesetzliche)?_c('span',[_vm._v(_vm._s(data.landname)+" +, "+_vm._s(' '))]):_vm._e(),(data.landname && !data.gesetzliche)?_c('span',[_vm._v(_vm._s(data.landname)+", "+_vm._s(' '))]):_vm._e(),(data.betragZahlenname !== 4)?_c('span',[_vm._v(" "+_vm._s(data.betrag.toLocaleString(("" + _vm.locale), { currency: ("" + (data.waehrung)) || 'EUR', minimumFractionDigits: 0, })))]):_vm._e(),_vm._v(" "+_vm._s(' ')+" "+_vm._s(_vm.suffixesDeposit[data.betragZahlenname])+" "+_vm._s(' ')+" "),(data.betragZahlenname !== 4)?_c('span',[_vm._v(_vm._s(data.waehrung))]):_vm._e()]),_c('br'),_c('span',{staticClass:"info-container-bp"},[_c('span',[_vm._v(_vm._s(data.bezeichnung)+" ")]),_c('span',[(data.infotext)?_c('v-tooltip',{staticClass:"info-text",attrs:{"top":"","color":_vm.baseColor,"max-width":"370"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.color}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',{staticClass:"hint-color"},[_vm._v(_vm._s(data.infotext))])]):_vm._e()],1)])])}),0):_c('div',[_vm._v("-")])]}},{key:"item.gesamtkapitalquoteLand",fn:function(ref){
        var item = ref.item;
return [(item.gesamtkapitalquoteLand)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.fieldValue(item.gesamtkapitalquoteLand, 'gesamtkapitalquoteLand')))])]):_c('div',[_c('span',[_vm._v("-")])])]}},{key:"item.gesamtkapitalquoteDurchschnitt",fn:function(ref){
        var item = ref.item;
return [(item.gesamtkapitalquoteDurchschnitt)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.fieldValue( item.gesamtkapitalquoteDurchschnitt, 'gesamtkapitalquoteDurchschnitt' )))])]):_c('div',[_c('span',[_vm._v("-")])])]}},{key:"item.gewinn",fn:function(ref){
        var item = ref.item;
return [(item.gewinn)?_c('div',{staticClass:"white-space"},_vm._l((item.gewinn),function(it,index){return _c('p',{key:index,staticClass:"mb-0"},[_c('span',{staticClass:"font-weight"},[_vm._v(_vm._s(it.jahr)+":")]),_vm._v(" "+_vm._s(_vm.fieldValue(it.gewinn, 'gewinn'))+" ")])}),0):_c('div',[_c('span',[_vm._v("-")])])]}},{key:"item.provisionsueberschuss",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.provisionsueberschuss, 'provisionsueberschuss'))+" ")]}},{key:"item.provisionsertrag",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.provisionsertrag, 'provisionsertrag'))+" ")]}},{key:"item.provisionsaufwendungen",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.provisionsaufwendungen, 'provisionsaufwendungen'))+" ")]}},{key:"item.grundpfandrecht",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.grundpfandrecht, 'grundpfandrecht'))+" ")]}},{key:"item.forderungenKreditinstitute",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue( item.forderungenKreditinstitute, 'forderungenKreditinstitute' ))+" ")]}},{key:"item.forderungenKunden",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.forderungenKunden, 'forderungenKunden'))+" ")]}},{key:"item.spareinlagen",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.spareinlagen, 'spareinlagen'))+" ")]}},{key:"item.andereVerbindlichkeiten",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.andereVerbindlichkeiten, 'andereVerbindlichkeiten'))+" ")]}},{key:"item.summeVerbindlichkeiten",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fieldValue(item.summeVerbindlichkeiten, 'summeVerbindlichkeiten'))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }