

































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapMutations, MutationMethod } from 'vuex';
import i18n from '@/plugins/i18n';
import {
  ADDITIONAL_HEADERS_BANKING_PROFILE,
  BACKEND_URL,
  BASE_BLACK_COLOR,
  BASE_COLOR,
  DEFAULT_HEADERS_BANKING_PROFILE,
  SUFFIXES,
  SUFFIXES_DEPOSIT,
} from '@/utils/constants';
import { exportXLS, fileFirstRowText, fileName } from '@/utils/helpers';

interface HeaderData {
  value: string;
  text: string;
  sortable: boolean;
  children?: Array<{ text: string; value: string; sortable: boolean }>;
}
interface RowsData {
  anlagevolumen: object[];
  kreditvolumen: object[];
  provisionseinnahmen: object[];
}

@Component({
  methods: {
    ...mapMutations({
      setBankDetails: 'bankingProfile/setBankDetails',
    }),
  },
})
export default class BankingProfileTable extends Vue {
  @Prop({ default: [] }) rows!: Array<RowsData>;
  @Prop() headers!: Array<HeaderData>;
  @Prop() loading!: Boolean;
  @Prop() content?: Object;
  [x: string]: any;
  color = BASE_BLACK_COLOR;
  baseColor = BASE_COLOR;
  url: String = BACKEND_URL;
  dotsMenu = false;
  language = i18n.locale;
  suffixesDeposit = SUFFIXES_DEPOSIT;
  setBankDetails!: MutationMethod;

  get locale() {
    return this.language === 'de' ? 'de-DE' : 'en-US';
  }
  get rowsItems() {
    if (this.loading) {
      return [];
    }
    return (this.rows || []).map((row) => {
      const {
        anlagevolumen,
        kreditvolumen,
        provisionseinnahmen,
        ...restFields
      } = row;
      if (anlagevolumen?.length) {
        Object.keys(anlagevolumen[0]).forEach((key) => {
          Object.assign(restFields, { [key]: anlagevolumen[0][key] || '' }, {});
        });
      }
      if (kreditvolumen?.length) {
        Object.keys(kreditvolumen[0]).forEach((key) => {
          Object.assign(restFields, { [key]: kreditvolumen[0][key] || '' }, {});
        });
      }
      if (provisionseinnahmen?.length) {
        Object.keys(provisionseinnahmen[0]).forEach((key) => {
          Object.assign(
            restFields,
            { [key]: provisionseinnahmen[0][key] || '' },
            {}
          );
        });
      }
      return restFields;
    });
  }
  get headersItems() {
    const allHeaders: Array<HeaderData> = [];
    this.headers.forEach((header) => {
      if (
        ['provisionseinnahmen', 'kreditvolumen', 'anlagevolumen'].includes(
          header.value
        )
      ) {
        (header.children || []).forEach((item) => allHeaders.push(item));
      }
      if (
        !['provisionseinnahmen', 'kreditvolumen', 'anlagevolumen'].includes(
          header.value
        )
      ) {
        allHeaders.push(header);
      }
    });
    const order = [
      'ordinalNumber',
      'anbietername',
      'standortlaender',
      'schwerpunkte',
      'bankratings',
      'gesamtkapitalquote',
      'eigenkapitalrendite',
      'landkuerzel',
    ];
    return allHeaders.sort((a, b) => {
      const indexA = order.indexOf(a.value);
      const indexB = order.indexOf(b.value);
      return indexA < 0 ? 1 : indexB < 0 ? -1 : indexA - indexB;
    });
  }

  customSort(items, sortBy, sortDesc) {
    const fields = [
      'einlagensicherung',
      'marktkapitalisierung',
      'filialen',
      'mitarbeiter',
    ];
    if (sortBy.length) {
      const sorted = items.sort((a, b) => {
        if (sortDesc.includes(true)) {
          if (!a[sortBy]) {
            return 1;
          }
          if (a[sortBy] && typeof a[sortBy] === 'boolean') {
            return a[sortBy] === b[sortBy] ? 0 : a[sortBy] ? -1 : 1;
          }
          if (a[sortBy] && typeof a[sortBy] === 'string') {
            return a[sortBy].trim().toLowerCase() <
              (b[sortBy] || '').trim().toLowerCase() || ''
              ? 1
              : -1;
          }
          if (Array.isArray(a[sortBy]) && !a[sortBy]?.length) {
            return 1;
          }
          if (Array.isArray(a[sortBy]) && a[sortBy]?.length) {
            if (typeof a[sortBy][0] === 'string') {
              return (b[sortBy] || ['']).join().trim().toLowerCase() <
                a[sortBy].join().trim().toLowerCase()
                ? -1
                : 1;
            }
            if (typeof a[sortBy][0] === 'object') {
              if (fields.includes(sortBy[0])) {
                if (sortBy[0] === 'einlagensicherung') {
                  if (!Number.isFinite(+a[sortBy]?.[0]?.betrag)) {
                    return 1;
                  }
                  if (Number.isFinite(+a[sortBy]?.[0]?.betrag)) {
                    const first = +a[sortBy]?.[0]?.betrag;
                    const second = +b[sortBy]?.[0]?.betrag || 0;
                    return second < first ? -1 : second > first ? 1 : -1;
                  }
                }
                if (sortBy[0] === 'marktkapitalisierung') {
                  if (!Number.isFinite(+a[sortBy]?.[0]?.bilanzsumme)) {
                    return 1;
                  }
                  if (Number.isFinite(+a[sortBy]?.[0]?.bilanzsumme)) {
                    const first = +a[sortBy]?.[0]?.bilanzsumme;
                    const second = +b[sortBy]?.[0]?.bilanzsumme || 0;
                    return second < first ? -1 : second > first ? 1 : -1;
                  }
                }
                if (sortBy[0] === 'filialen' || sortBy[0] === 'mitarbeiter') {
                  if (!Number.isFinite(+a[sortBy]?.[0]?.anzahl)) {
                    return 1;
                  }
                  if (Number.isFinite(+a[sortBy]?.[0]?.anzahl)) {
                    const first = +a[sortBy]?.[0]?.anzahl;
                    const second = +b[sortBy]?.[0]?.anzahl || 0;
                    return second < first ? -1 : second > first ? 1 : -1;
                  }
                }
              }
              if (
                a[sortBy]?.[0].hasOwnProperty('jahr') &&
                !fields.includes(sortBy[0])
              ) {
                if (!Number.isFinite(+a[sortBy]?.[0]?.[sortBy[0]])) {
                  return 1;
                }
                if (Number.isFinite(+a[sortBy]?.[0]?.[sortBy[0]])) {
                  const first = +a[sortBy]?.[0]?.[sortBy[0]];
                  const second = +b?.[sortBy]?.[0]?.[sortBy[0]] || 0;
                  return second < first ? -1 : second > first ? 1 : -1;
                }
              }
              if (
                !fields.includes(sortBy[0]) &&
                !a[sortBy]?.[0].hasOwnProperty('jahr')
              ) {
                return (a[sortBy]?.length || 0) < b[sortBy]?.length ? 1 : -1;
              }
            }
          }
          if (!Number.isFinite(+a[sortBy])) {
            return 1;
          }
          if (Number.isFinite(+a[sortBy])) {
            const first = +a[sortBy];
            const second = +b[sortBy] || 0;
            return second - first;
          }
        } else {
          if (!a[sortBy]) {
            return -1;
          }
          if (a[sortBy] && typeof a[sortBy] === 'boolean') {
            return a[sortBy] === b[sortBy] ? 0 : a[sortBy] ? 1 : -1;
          }
          if (a[sortBy] && typeof a[sortBy] === 'string') {
            return (b[sortBy] || '').trim().toLowerCase() <
              a[sortBy].trim().toLowerCase()
              ? 1
              : -1;
          }
          if (Array.isArray(a[sortBy]) && !a[sortBy]?.length) {
            return -1;
          }
          if (Array.isArray(a[sortBy]) && a[sortBy]?.length) {
            if (typeof a[sortBy][0] === 'string') {
              return (b[sortBy] || ['']).join().trim().toLowerCase() <
                a[sortBy].join().trim().toLowerCase()
                ? 1
                : -1;
            }
            if (typeof a[sortBy][0] === 'object') {
              if (fields.includes(sortBy[0])) {
                if (sortBy[0] === 'einlagensicherung') {
                  if (!Number.isFinite(+a[sortBy]?.[0]?.betrag)) {
                    return -1;
                  }
                  if (Number.isFinite(+a[sortBy]?.[0]?.betrag)) {
                    const first = +a[sortBy]?.[0]?.betrag;
                    const second = +b[sortBy]?.[0]?.betrag || 0;
                    return second < first ? 1 : second > first ? -1 : 1;
                  }
                }
                if (sortBy[0] === 'marktkapitalisierung') {
                  if (!Number.isFinite(+a[sortBy]?.[0]?.bilanzsumme)) {
                    return -1;
                  }
                  if (Number.isFinite(+a[sortBy]?.[0]?.bilanzsumme)) {
                    const first = +a[sortBy]?.[0]?.bilanzsumme;
                    const second = +b[sortBy]?.[0]?.bilanzsumme || 0;
                    return second < first ? 1 : second > first ? -1 : 1;
                  }
                }
                if (sortBy[0] === 'filialen' || sortBy[0] === 'mitarbeiter') {
                  if (!Number.isFinite(+a[sortBy]?.[0]?.anzahl)) {
                    return -1;
                  }
                  if (Number.isFinite(+a[sortBy]?.[0]?.anzahl)) {
                    const first = +a[sortBy]?.[0]?.anzahl;
                    const second = +b[sortBy]?.[0]?.anzahl || 0;
                    return second < first ? 1 : second > first ? -1 : 1;
                  }
                }
              }
              if (
                a[sortBy]?.[0]?.hasOwnProperty('jahr') &&
                !fields.includes(sortBy[0])
              ) {
                if (!Number.isFinite(+a[sortBy]?.[0]?.[sortBy[0]])) {
                  return -1;
                }
                if (Number.isFinite(+a[sortBy]?.[0]?.[sortBy[0]])) {
                  const first = +a[sortBy][0]?.[sortBy[0]];
                  const second = +b?.[sortBy]?.[0]?.[sortBy[0]] || 0;
                  return second < first ? 1 : second > first ? -1 : 1;
                }
              }
              if (
                !fields.includes(sortBy[0]) &&
                !a[sortBy]?.[0]?.hasOwnProperty('jahr')
              ) {
                return (b[sortBy]?.length || 0) < a[sortBy].length ? 1 : -1;
              }
            }
          }
          if (!Number.isFinite(+a[sortBy])) {
            return -1;
          }
          if (Number.isFinite(+a[sortBy])) {
            const first = +a[sortBy];
            const second = +b[sortBy] || 0;
            return first - second;
          }
        }
      });
      return sorted.map((item, ind) => {
        return {
          ...item,
          ordinalNumber: ind + 1,
        };
      });
    }
    return items.map((item, ind) => {
      return {
        ...item,
        ordinalNumber: ind + 1,
      };
    });
  }
  onRowClick(currentRow) {
    const rows = JSON.parse(localStorage.getItem('rows') || '[]');
    const headers = [...DEFAULT_HEADERS_BANKING_PROFILE].concat([
      ...ADDITIONAL_HEADERS_BANKING_PROFILE,
    ]);
    const row = {
      id: '',
    };
    if (rows.length) {
      const rowWithAllFields = rows.find(
        (item) => String(item.id) === String(currentRow.id)
      );
      Object.assign(row, rowWithAllFields);
    } else {
      Object.assign(row, currentRow);
    }
    this.setBankDetails({
      headers,
      row,
    });
    if (
      this.$router.currentRoute.fullPath !==
      `/banking-profile/bank-details/${row.id}`
    ) {
      this.$router
        .push(`/banking-profile/bank-details/${row.id}`)
        .catch(() => {});
    }
  }
  saveXLS() {
    const firstRowText = fileFirstRowText(
      (this.content as any)?.timestamp,
      this.$date
    );
    const name = fileName((this.content as any)?.title);
    exportXLS((this.$refs?.document as Vue)?.$el, name, firstRowText);
  }
  fieldValue(value, field) {
    if (value) {
      const suffix = SUFFIXES[field] ? SUFFIXES[field] : '';
      if (
        ['>', '<', '-', '+'].some((symbol) => String(value).includes(symbol)) ||
        typeof +value !== 'number'
      ) {
        return `${value} ${suffix}`;
      } else {
        const fieldsWithCurrency = [
          'bilanzsumme',
          'eigenkapital',
          'marktkapitalisierung',
          'gewinn',
          'provisionsueberschuss',
          'provisionsertrag',
          'provisionsaufwendungen',
          'summeVerbindlichkeiten',
          'andereVerbindlichkeiten',
          'spareinlagen',
          'forderungenKunden',
          'forderungenKreditinstitute',
          'grundpfandrecht',
        ];
        const minimumFractionFields = [
          'gesamtkapitalquoteLand',
          'gesamtkapitalquoteDurchschnitt',
          'eigenkapitalrenditeDurchschnitt',
          'eigenkapitalrendite',
          'leverageRatio',
          'gesamtkapitalquote',
          'kernkapitalquote',
        ];
        if (minimumFractionFields.includes(field)) {
          return `${Number(value).toLocaleString(this.locale, {
            minimumFractionDigits: 2,
          })} ${suffix}`;
        }
        if (fieldsWithCurrency.includes(field)) {
          return `${Number(value).toLocaleString(this.locale, {
            currency: 'EUR',
          })} ${suffix}`;
        }
        return `${Number(value).toLocaleString(this.locale, {
          currency: 'EUR',
        })} ${suffix}`;
      }
    } else {
      return '-';
    }
  }
  fieldValueFilialen(value) {
    if (+value === 0) {
      return 'keine';
    }
    return value;
  }
  getSlotName(header) {
    return `header.${header.value}`;
  }
}
